import { createLocalstorageState, useToday } from '@highloop-pkg/hooks';
import { getDay } from '@highloop-pkg/date-utils';
import axios from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';
import { config } from '../../config';
import { ICalendar, ICalendarProject } from '../interfaces/calendar';
import { ITask } from '../interfaces/task';
import { getAuthHeaders, notLoggedInHandler } from './useAres';

export let useCurrentCalendar = createLocalstorageState<string>('currentCalendar', null);

export let useWeekriseBoxOpen = createLocalstorageState<boolean>('weekriseBoxOpen', true);

export let dashboardApi = axios.create({
  baseURL: config.weekriseApi
});

export let dashboardFetcher = async (url: string) =>
  dashboardApi
    .get(url, {
      headers: await getAuthHeaders()
    })
    .then(res => res.data)
    .catch(err => {
      if (err?.response?.status == 401) notLoggedInHandler();
      throw err;
    });

export let useWeekrise = () => {
  let today = useToday();
  let date = useMemo(() => getDay(today).replaceAll('-', '/'), [today]);
  let { data, error, revalidate } = useSWR<{
    enabled: boolean;
    calendar: ICalendar[];
    projects: ICalendarProject[];
    tasks: ITask[];
    rolledOver: ITask[];
  }>(date ? `/highloop/start?date=${date}` : null, dashboardFetcher);

  return {
    calendars: data?.calendar,
    projects: data?.projects,
    tasks: data?.tasks,
    enabled: data?.enabled,
    rolledOver: data?.rolledOver,
    error,
    loading: !data && !error
  };
};
