import React from 'react';
import { config } from '../../config';
import { BaseLayout } from '@highloop-pkg/layout';
import { useUser } from '../state/useAres';

export let Layout = ({ children }: { children: React.ReactNode }) => {
  let { user } = useUser();

  return (
    <>
      <BaseLayout
        name="Highloop"
        user={user}
        navItems={[
          {
            children: 'Teams',
            href: '/', //getUrlWithParams('/', { next }),
            active: true
          },
          {
            children: 'Account',
            href: config.accountFrontend //  getUrlWithParams(config.accountFrontend, { next })
          }
        ]}
      >
        {children}
      </BaseLayout>
    </>
  );
};
