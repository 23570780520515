import { Button, Spacer, Spinner, Text } from '@vapor/ui';
import { keyframes, styled } from 'buttered';
import clsx from 'clsx';
import Link from 'next/link';
import useDelayed from 'use-delayed';
import { useRef, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useMeasure, useScroll } from 'react-use';
import { ICalendar } from '../../interfaces/calendar';
import { ITask } from '../../interfaces/task';
import { useCurrentCalendar, useWeekriseBoxOpen } from '../../state/calendar';
import { SmallTask } from './task';

let WeekriseWrapper = styled('div')`
  overflow: hidden;

  header {
    padding: 12px 23px;
    border-bottom: solid var(--vapor-accent-3) 1px;
    margin-bottom: 10px;
    display: flex;

    p {
      height: 24px;
      line-height: 24px !important;
    }
  }

  footer {
    padding: 0px 20px 10px 20px;
  }

  @media screen and (max-width: 1000px) {
    overflow: hidden;
  }

  &:not(.full) {
    overflow-x: hidden;
    border: solid var(--vapor-accent-3) 1px;
    border-radius: 10px;
    margin-bottom: 20px;

    main {
      padding: 0px 10px;
    }
  }
`;

let CalendarWrapper = styled('div')`
  position: relative;
  width: 100%;
  display: flex;

  &:not(.full) {
    padding: 0px 10px;
  }

  ${() =>
    typeof window != 'undefined' && window.navigator.userAgent.includes('Windows')
      ? `
      ::-webkit-scrollbar {
        width: 0;
        height: 7px;
        background: white;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: #ddd;
      }
    `
      : ''}

  .inner {
    overflow: auto;
    display: flex;
    padding: 4px 2px;
  }
`;

let Calendar = styled('button')`
  border: var(--vapor-border);
  padding: 10px;
  border-radius: 12px;
  display: flex;
  min-width: 160px;
  transition: all 0.3s;
  background: white;
  color: black;

  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    height: 40px;
    margin-left: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bar {
    height: 40px;
    width: 5px;
    background: var(--vapor-primary);
    border-radius: 12px;
  }

  &:hover {
    background: #fafafa;
    border: solid #ccc 1px;
  }

  &.selected {
    border: solid var(--vapor-primary) 1px;
    box-shadow: 0px 0px 0px 1px var(--vapor-primary);
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

let CalendarShadow = styled('div')`
  width: 20px;
  position: absolute;
  top: 0px;
  bottom: 0px;

  &.left {
    background-image: linear-gradient(90deg, white, rgba(255, 255, 255, 0));
    left: 10px;
  }

  &.right {
    background-image: linear-gradient(-90deg, white, rgba(255, 255, 255, 0));
    right: 10px;
  }

  &.full {
    &.left {
      left: 0px !important;
    }

    &.right {
      right: 0px !important;
    }
  }
`;

let fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

let fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export let Calendars = ({
  calendars,
  tasks,
  rolledOver,
  full
}: {
  calendars: ICalendar[];
  tasks: ITask[];
  rolledOver: ITask[];
  full?: boolean;
}) => {
  let [current, setCurrent] = useCurrentCalendar();
  let [open, setOpen] = useWeekriseBoxOpen();
  let delayedOpen = useDelayed(open, 500);

  let [fadeInType, setFadeInType] = useState<'left' | 'right'>();

  let scrollRef = useRef<HTMLDivElement>();
  let elementRefs = useRef<{ [key: string]: HTMLButtonElement }>({});
  let lastCalendarIndexRef = useRef<number>();
  let canScrollSmoothRef = useRef(false);

  let { x } = useScroll(scrollRef);
  let [tasksUlRef, { height: tasksUlHeight }] = useMeasure();
  let [wrapperRef, { height: wrapperHeight }] = useMeasure();

  let isAtEnd = scrollRef.current
    ? x >= scrollRef.current.scrollWidth - scrollRef.current.clientWidth
    : false;

  useEffect(() => {
    setTimeout(() => {
      canScrollSmoothRef.current = true;
    }, 500);
  }, []);

  useEffect(() => {
    if (!open) lastCalendarIndexRef.current = undefined;
  }, [open]);

  useEffect(() => {
    if (!current || !elementRefs.current[current]) return;

    let currentIndex = calendars.map(c => c.id).indexOf(current);
    let newScroll = currentIndex * 175 - (scrollRef.current.clientWidth - 175) / 2;

    scrollRef.current.scrollTo({
      left: newScroll > 0 ? newScroll : 0,
      behavior: canScrollSmoothRef.current ? 'smooth' : undefined
    });
  }, [current, calendars, elementRefs]);

  useEffect(() => {
    if (typeof window != 'undefined') {
      let currentCalendar = calendars.find(c => c.id === current);
      if (!currentCalendar) {
        setCurrent(calendars[0].id);
      }
    }
  }, [current, calendars]);

  let currentCalendar = useMemo(() => {
    return calendars.find(c => c.id === current);
  }, [current, calendars]);

  useEffect(() => {
    let index = calendars.map(c => c.id).indexOf(current);

    if (typeof lastCalendarIndexRef.current == 'number') {
      if (index > lastCalendarIndexRef.current) {
        setFadeInType('right');
      } else if (index < lastCalendarIndexRef.current) {
        setFadeInType('left');
      }
    }

    lastCalendarIndexRef.current = index;
  }, [current, calendars]);

  let tasksToShow = useMemo(() => {
    return [...tasks, ...rolledOver.map(r => ({ ...r, rolledOver: true }))]
      .filter(t => t.calendarId === current)
      .sort((a, b) => (a.done ? 1 : b.done ? -1 : 0))
      .slice(0, full ? undefined : 5);
  }, [tasks, rolledOver, full, current]);

  return (
    <WeekriseWrapper
      style={{
        height: open ? wrapperHeight : 50,
        transition: delayedOpen != open ? `all ${wrapperHeight * 0.5 + 125}ms` : 'none'
      }}
      className={clsx({ full })}
    >
      <div ref={wrapperRef}>
        {!full && (
          <header>
            <Text size={16} weight={500} as="p">
              Weekrise
            </Text>

            <Spacer />

            <Button
              iconRotation={open ? 0 : 180}
              onClick={() => setOpen(!open)}
              aria-label="Open"
              size="smaller"
              icon={<ChevronUp />}
            />
          </header>
        )}

        {(delayedOpen || open) && (
          <>
            <main>
              {calendars.length > 1 && (
                <CalendarWrapper className={clsx({ full })}>
                  <CalendarShadow
                    className={clsx('left', { full })}
                    style={{ opacity: x > 0 ? 1 : 0 }}
                  />

                  <div className="inner" ref={scrollRef}>
                    {calendars.map(calendar => (
                      <Calendar
                        key={calendar.id}
                        ref={el => (elementRefs.current[calendar.id] = el)}
                        className={clsx({ selected: current == calendar.id })}
                        onClick={() => setCurrent(calendar.id)}
                      >
                        <div className="bar" />
                        <h1>{calendar.name}</h1>
                      </Calendar>
                    ))}
                  </div>

                  <CalendarShadow
                    className={clsx('right', { full })}
                    style={{ opacity: isAtEnd ? 0 : 1 }}
                  />
                </CalendarWrapper>
              )}

              <div
                style={{
                  marginTop: 5,
                  height: tasksUlHeight == 0 ? undefined : tasksUlHeight + 10,
                  transition: 'all .3s',
                  overflowY: full ? undefined : 'hidden',
                  animation: fadeInType
                    ? `${fadeInType == 'left' ? fadeInLeft : fadeInRight} .5s`
                    : undefined
                }}
              >
                {tasksToShow.length > 0 ? (
                  <ul style={{ padding: full ? '10px 0px' : 10 }} ref={tasksUlRef}>
                    {tasksToShow.map(task => (
                      <SmallTask
                        key={task.id}
                        task={task}
                        date={task.date}
                        rolledOver={task.rolledOver}
                        withSubtasks
                      />
                    ))}
                  </ul>
                ) : (
                  <div ref={tasksUlRef}>
                    <p
                      style={{
                        padding: '10px 0px 0px 0px',
                        textAlign: full ? undefined : 'center',
                        fontWeight: 500
                      }}
                    >
                      No tasks{currentCalendar?.name ? ` in ${currentCalendar.name} ` : ' '}for
                      today.
                    </p>
                  </div>
                )}
              </div>
            </main>

            {!full && (
              <footer>
                <div style={{ width: 'fit-content', margin: '0px auto' }}>
                  <Link href="/tasks">
                    <a>
                      <Button as="div" size="small" icon={<ChevronDown />}>
                        Show More
                      </Button>
                    </a>
                  </Link>
                </div>
              </footer>
            )}
          </>
        )}
      </div>
    </WeekriseWrapper>
  );
};

export let LoadingCalendars = () => {
  let [open, setOpen] = useWeekriseBoxOpen();

  return (
    <WeekriseWrapper>
      <div>
        <header>
          <Text size={16} weight={500} as="p">
            Weekrise
          </Text>

          <Spacer />

          <Button
            iconRotation={open ? 0 : 180}
            onClick={() => setOpen(!open)}
            aria-label="Open"
            size="smaller"
            icon={<ChevronUp />}
          />
        </header>

        <main>
          <div style={{ margin: '30px auto 30px auto', width: 'fit-content' }}>
            <Spinner />
          </div>
        </main>
      </div>
    </WeekriseWrapper>
  );
};
