import { Checkbox, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import { ISubtask, ITask } from '../../interfaces/task';

let TaskWrapper = styled('li')`
  border: var(--vapor-border);
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  transition: all 0.3s;
  &:hover {
    border: var(--vapor-border-primary);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.18);
  }
  a {
    display: flex;
    flex-direction: column;
  }
  .box {
    padding: 12px 16px;
  }
  .content {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  .subtasks {
    /* margin-left: 20px; */
    border-top: var(--vapor-border);
    width: 100%;
  }
`;

export let SmallTask = ({
  task,
  rolledOver,
  date,
  withSubtasks,
  onCheckClick
}: {
  task: ITask;
  rolledOver?: boolean;
  date?: string;
  withSubtasks?: boolean;
  onCheckClick?: (d: {
    type: 'task' | 'subtask';
    task: ITask;
    subtask?: ISubtask;
    checked: boolean;
  }) => void;
}) => {
  let inner = (
    <div>
      <div className="box" style={{ display: 'flex' }}>
        <Checkbox
          isSelected={task.done}
          label="Done"
          hideLabel
          onChange={checked => {
            if (onCheckClick) onCheckClick({ task, checked, type: 'task' });
          }}
        />

        <div
          style={{
            maxWidth: 'calc(100% - 11px)'
          }}
        >
          <VerticalCenter>
            <div className="content">
              <Text size={16} weight={500} truncate>
                {task.name}
              </Text>

              {rolledOver && (
                <Text size={10} color="var(--vapor-accent-6)">
                  Rolled over
                </Text>
              )}
            </div>
          </VerticalCenter>
        </div>
      </div>

      {task.subtasks && task.subtasks.length > 0 && withSubtasks && (
        <div className="subtasks box">
          {task.subtasks.map((subtask, i) => (
            <div style={{ display: 'flex', marginTop: i == 0 ? 0 : 6 }} key={subtask.id}>
              <Checkbox
                isSelected={subtask.done}
                label="Done"
                hideLabel
                onChange={checked => {
                  if (onCheckClick) onCheckClick({ task, checked, subtask, type: 'subtask' });
                }}
              />

              <div
                style={{
                  maxWidth: 'calc(100% - 11px)'
                }}
              >
                <VerticalCenter>
                  <div className="content">
                    <Text truncate>{subtask.name}</Text>
                  </div>
                </VerticalCenter>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  if (!date) return <TaskWrapper>{inner}</TaskWrapper>;

  return (
    <TaskWrapper>
      <a
        href={`https://app.weekrise.com/cal/${task.calendarId}/${date.replace(
          /\//gi,
          '-'
        )}/task/${task.id}`}
      >
        {inner}
      </a>
    </TaskWrapper>
  );
};
